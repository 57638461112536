export default [
  {
    name: "chrysler",
  },
  {
    name: "honda",
  },
  {
    name: "mercedes-benz",
  },
  {
    name: "ram",
  },
  {
    name: "ford",
  },
  {
    name: "gmc",
  },
  {
    name: "audi",
  },
  {
    name: "subaru",
  },
  {
    name: "rolls-royce",
  },
  {
    name: "porsche",
  },
  {
    name: "bmw",
  },
  {
    name: "volvo",
  },
  {
    name: "lincoln",
  },
  {
    name: "maserati",
  },
  {
    name: "acura",
  },
  {
    name: "mclaren",
  },
  {
    name: "infiniti",
  },
  {
    name: "fiat",
  },
  {
    name: "scion",
  },
  {
    name: "dodge",
  },
  {
    name: "bentley",
  },
  {
    name: "aston-martin",
  },
  {
    name: "chevrolet",
  },
  {
    name: "land-rover",
  },
  {
    name: "mitsubishi",
  },
  {
    name: "volkswagen",
  },
  {
    name: "toyota",
  },
  {
    name: "jeep",
  },
  {
    name: "hyundai",
  },
  {
    name: "cadillac",
  },
  {
    name: "lamborghini",
  },
  {
    name: "lexus",
  },
  {
    name: "alfa-romeo",
  },
  {
    name: "mini",
  },
  {
    name: "kia",
  },
  {
    name: "ferrari",
  },
  {
    name: "mazda",
  },
  {
    name: "nissan",
  },
  {
    name: "buick",
  },
  {
    name: "jaguar",
  },
];
